import {JsonPipe, NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input, output, Signal, signal} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {WorkTimePlannedTime, WorkTimePlannedTimeType} from '@dv/kitadmin/models';
import {MinutesInputDirective} from '@dv/kitadmin/ui';
import {
    ButtonComponent,
    ButtonListComponent,
    DisplayNamePipe,
    DvTimeFormatPipe,
    ElasticTextInputDirective,
    RequirePermissionAttributeDirective,
    RequirePermissionStructuralDirective,
    ValidBisDirective,
    ValidVonDirective,
} from '@dv/shared/angular';
import {WorkTimeControlServiceSaveManualEditRequestParams} from '@dv/shared/backend/api/work-time-control.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxAngestellteStandortWorkDay} from '@dv/shared/backend/model/jax-angestellte-standort-work-day';
import {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {JaxWorkTimeControllingAbsence} from '@dv/shared/backend/model/jax-work-time-controlling-absence';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {JaxWorkTimeControllingAbsences} from '@dv/shared/backend/model/jax-work-time-controlling-absences';
import {JaxWorkTimeControllingTimes} from '@dv/shared/backend/model/jax-work-time-controlling-times';
import {Displayable, DvbDateUtil, DvbRestUtil, isNullish, TimeRange, TimeRangeUtil} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {EntityMap} from '@ngrx/signals/entities';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {
    AngestellteStandortWorkDay,
    WorkTimeControllingAbsences,
} from '../work-time-controlling-table/work-time-controlling-table.models';

@Component({
    selector: 'dv-work-time-controlling-day-detail',
    standalone: true,
    imports: [
        ElasticTextInputDirective,
        FormsModule,
        TooltipModule,
        JsonPipe,
        ButtonComponent,
        TranslocoModule,
        RequirePermissionStructuralDirective,
        ButtonListComponent,
        MinutesInputDirective,
        DisplayNamePipe,
        NgClass,
        DvTimeFormatPipe,
        ValidVonDirective,
        ValidBisDirective,
        RequirePermissionAttributeDirective,
    ],
    templateUrl: './work-time-controlling-day-detail.component.html',
    styleUrl: './work-time-controlling-day-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingDayDetailComponent {
    public standOrtWorkDays = input<AngestellteStandortWorkDay[]>([]);
    public absences = input<WorkTimeControllingAbsences[]>([]);
    public absenceTypes = input<JaxWorkTimeControllingAbsenceType[]>([]);
    public absenceTermine = input<JaxPlannedAbsenceTermin[]>([]);
    public kinderOrteById = input.required<EntityMap<Displayable>>();
    public kinderOrtFraktionenById = input.required<EntityMap<Displayable>>();
    public angestellteId = input.required<string>();
    public inputDate = input.required<BackendLocalDate>();
    public selectedDate = computed(() => DvbDateUtil.toMoment(this.inputDate()));

    public readonly edit = output<WorkTimeControlServiceSaveManualEditRequestParams>();

    private errorService = inject(ErrorService);

    private plannedTimeRanges: Signal<WorkTimePlannedTime[]> = computed(() => {
        const kinderOrteById = this.kinderOrteById();
        const kinderOrtFraktionenById = this.kinderOrtFraktionenById();

        return this.standOrtWorkDays().reduce((acc: WorkTimePlannedTime[], workDay: JaxAngestellteStandortWorkDay) => [
                ...acc,
                ...workDay.plannedTimeRanges?.map(
                    (timeRange: JaxTimeRange) => {
                        const kinderOrtName = workDay.kinderOrtId ?
                            kinderOrteById[workDay.kinderOrtId]?.getDisplayName() :
                            '';
                        const kinderOrtFraktionName = workDay.kinderOrtFraktionId ?
                            kinderOrtFraktionenById[workDay.kinderOrtFraktionId]?.getDisplayName() :
                            '';

                        return new WorkTimePlannedTime(
                            kinderOrtName ? kinderOrtName : kinderOrtFraktionName ?? '',
                            WorkTimePlannedTimeType.WORK,
                            TimeRange.apiResponseTransformer(timeRange),
                        );
                    },
                ) ?? [],
            ],
            [],
        );
    });

    private plannedAbsenceTermine: Signal<WorkTimePlannedTime[]> = computed(() =>
        this.absenceTermine().map((absenceTermin: JaxPlannedAbsenceTermin) =>
            new WorkTimePlannedTime(
                absenceTermin.title ?? '',
                WorkTimePlannedTimeType.TERMIN,
                TimeRange.apiResponseTransformer(absenceTermin.timeRange),
            ),
        ));

    public plannedTimes: Signal<WorkTimePlannedTime[]> = computed(() => {
        const plannedTimeRanges: WorkTimePlannedTime[] = this.plannedTimeRanges();
        const plannedAbsenceTermine: WorkTimePlannedTime[] = this.plannedAbsenceTermine();

        return [...plannedTimeRanges, ...plannedAbsenceTermine].sort((a, b) => {
            return TimeRangeUtil.TIME_RANGE_COMPARATOR(a.timeRange, b.timeRange);
        });
    });

    public state = computed(() => {
        return {
            workDays: signal(structuredClone(this.standOrtWorkDays())),
            absences: signal(structuredClone(this.absences())),
            absenceTermine: signal(structuredClone(this.absenceTermine())),
            plannedTimes: signal(this.plannedTimes()),
        };
    });

    public addWorkDay(): void {
        this.state().workDays.update((workDays: AngestellteStandortWorkDay[]) => [
            ...workDays,
            {
                kinderOrtId: undefined,
                kinderOrtFraktionId: undefined,
                editPermitted: true,
                plannedTimeRanges: [],
                actualTimeRanges: [
                    {
                        von: '00:00',
                        bis: '00:00',
                    },
                ],
            },
        ]);
    }

    public addAbsence(): void {
        this.state().absences.update((absences: WorkTimeControllingAbsences[]) => [
            ...absences,
            {
                angestellteId: this.angestellteId(),
                date: this.inputDate(),
                editPermitted: true,
                absences: [
                    {
                        absenceTypeId: '',
                        von: '00:00',
                        bis: '00:00',
                    },
                ],
            },
        ]);
    }

    public removeTimeRange(workDay: JaxAngestellteStandortWorkDay, index: number): void {
        workDay.actualTimeRanges?.splice(index, 1);
    }

    public removeAbsence(
        jaxWorkTimeControllingAbsences: JaxWorkTimeControllingAbsences,
        absenceIndex: number,
    ): void {
        jaxWorkTimeControllingAbsences.absences.splice(absenceIndex, 1);
    }

    public updateAbsenceMinutes(absence: JaxWorkTimeControllingAbsence): void {
        if (isNullish(absence.von) || isNullish(absence.bis)) {
            absence.minutes = undefined;

            return;
        }

        absence.minutes = DvbDateUtil.getTimeDiff(
            DvbRestUtil.localeHHMMTimeToMomentChecked(absence.von),
            DvbRestUtil.localeHHMMTimeToMomentChecked(absence.bis));
    }

    public resetChanges(): void {
        this.state().workDays.set(structuredClone(this.standOrtWorkDays()));
        this.state().absences.set(structuredClone(this.absences()));
    }

    public save($event: Event, detailForm: NgForm): void {

        this.errorService.clearAll();

        const isFormValid = detailForm.valid ?? false;

        this.errorService.handleValidationError(isFormValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isFormValid) {
            detailForm.onSubmit($event);

            return;
        }

        const combinedTimes: JaxWorkTimeControllingTimes[] = this.state().workDays().map(
            (x: JaxAngestellteStandortWorkDay) => JSON.parse(JSON.stringify(x)),
        ).map(workDay => {
            const times: JaxWorkTimeControllingTimes = {
                kinderOrtId: workDay.kinderOrtId,
                kinderOrtFraktionId: workDay.kinderOrtFraktionId,
                actualTimes: workDay.actualTimeRanges,
            };

            return times;
        }).reduce(
            (acc: JaxWorkTimeControllingTimes[], current: JaxWorkTimeControllingTimes) => {
                const existingWorkDay = acc.find(wd =>
                    wd.kinderOrtId === current.kinderOrtId &&
                    wd.kinderOrtFraktionId === current.kinderOrtFraktionId,
                );
                if (existingWorkDay) {
                    existingWorkDay.actualTimes = [...existingWorkDay.actualTimes ?? [], ...current.actualTimes ?? []];

                    return acc;
                }

                return [...acc, current];
            },
            [],
        );

        const combinedAbsences: JaxWorkTimeControllingAbsences[] = this.state()
            .absences()
            .map((x: JaxWorkTimeControllingAbsences) => JSON.parse(JSON.stringify(x)))
            .reduce(
                (
                    acc: JaxWorkTimeControllingAbsences[],
                    absence: JaxWorkTimeControllingAbsences,
                ) => [...acc, {
                    angestellteId: absence.angestellteId,
                    kinderOrtId: absence.kinderOrtId,
                    triggerId: absence.triggerId,
                    date: absence.date,
                    absences: absence.absences,
                }],
                [],
            );

        this.edit.emit({
            angestellteId: this.angestellteId(),
            jaxAngestellteEditDay: {
                angestellteId: this.angestellteId(),
                date: this.inputDate(),
                standortTimes: combinedTimes,
                absences: combinedAbsences,
            },
        });
    }
}
