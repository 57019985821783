/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxAngestellteStandortWorkDay} from '@dv/shared/backend/model/jax-angestellte-standort-work-day';
import type {JaxAngestellteWorkDay} from '@dv/shared/backend/model/jax-angestellte-work-day';
import type {JaxAngestellteWorkDays} from '@dv/shared/backend/model/jax-angestellte-work-days';
import type {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import type {JaxWorkTimeControllingAbsences} from '@dv/shared/backend/model/jax-work-time-controlling-absences';
import {isNullish, isPresent} from '@dv/shared/code';

export type SortOrder = 'asc' | 'desc';
export type SortBy =
    'displayname'
    | 'expectedMinutes'
    | 'plannedMinutes'
    | 'actualMinutes'
    | 'absenceMinutes'
    | 'timeBalance';

export enum WarningType {
    MISSING_ABSENCE_TIME = 'MISSING_ABSENCE_TIME',
}

export const warningClasses: Record<WarningType, string> = {
    MISSING_ABSENCE_TIME: 'missing-absence-time',
};

export type WorkTimeControllingHoursDaily = {
    date: BackendLocalDate;
    expectedMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    timeBalance: number;
    ferienkontingent: number;
    expanded: boolean;
    warning: WarningType | undefined;
    standortWorkDays: AngestellteStandortWorkDay[];
    absences: WorkTimeControllingAbsences[];
    absenceTermine: JaxPlannedAbsenceTermin[];
};

export type WorkTimeControllingTableData = {
    id: string;
    angestellteDisplayName: string;
    expectedMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    timeBalance: number;
    ferienkontingent: number;
    expanded: boolean;
    dailyHours: WorkTimeControllingHoursDaily[];
    warning?: WarningType;
};

export type AngestellteStandortWorkDay = JaxAngestellteStandortWorkDay & { editPermitted: boolean };
export type WorkTimeControllingAbsences = JaxWorkTimeControllingAbsences & { editPermitted: boolean };

export function sumMinutes(
    controlData: JaxAngestellteWorkDays,
    accessor: (workDay: JaxAngestellteWorkDay) => number,
): number {
    return controlData.angestellteWorkDays.map(workDay => accessor(workDay))
        .reduce((a, b) => a + b, 0);
}

export function createWorkTimeControllingTableData(
    data: JaxWorkTimeControlling,
    authStore: AuthStore,
): WorkTimeControllingTableData[] {

    const editAllPermitted =
        authStore.hasPermission(PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT + WILDCARD_TOKEN);
    const editAnyPermitted =
        authStore.hasPermission(`${PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT}general`);

    return data.angestellteWorkDays.map(d => {
        let timeBalance = d.timeBalanceAtStartOfMonth ?? 0;

        const dailyData: WorkTimeControllingTableData = {
            dailyHours: d.angestellteWorkDays
                .sort((a, b) => a.date.localeCompare(b.date))
                .map(daily => {
                    const missingAbsenceTimes = isPresent(daily.absences.flatMap(a => a.absences)
                        .find(a => isNullish(a.minutes) && (isNullish(a.von) || isNullish(a.bis))));

                    timeBalance +=
                        daily.actualMinutes + daily.paidAbsenceMinutes - daily.expectedMinutes;

                    const warning: WarningType | undefined = missingAbsenceTimes ?
                        WarningType.MISSING_ABSENCE_TIME :
                        undefined;

                    const standortWorkDays = initStandortWorkDays(daily, editAllPermitted, editAnyPermitted, authStore);
                    const absences = initAbsences(daily, editAllPermitted, editAnyPermitted, authStore);

                    return {
                        expanded: false,
                        date: daily.date,
                        expectedMinutes: daily.expectedMinutes,
                        plannedMinutes: daily.plannedMinutes,
                        actualMinutes: daily.actualMinutes,
                        absenceMinutes: daily.absenceMinutes,
                        timeBalance,
                        ferienkontingent: 0,
                        warning,
                        standortWorkDays,
                        absences,
                        absenceTermine: daily.plannedAbsenceTermine,
                    } satisfies WorkTimeControllingHoursDaily;
                })
                .sort((a, b) => a.date.localeCompare(b.date)),
            id: d.angestellteId,
            angestellteDisplayName: d.angestellteDisplayName,
            expectedMinutes: sumMinutes(d, daily => daily.expectedMinutes),
            plannedMinutes: sumMinutes(d, daily => daily.plannedMinutes),
            actualMinutes: sumMinutes(d, daily => daily.actualMinutes),
            absenceMinutes: sumMinutes(d, daily => daily.absenceMinutes),
            timeBalance,
            ferienkontingent: 0,
            expanded: false,
        };

        const warnings = dailyData.dailyHours.map(daily => daily.warning)
            .filter(warning => isPresent(warning));
        if (warnings.length > 0) {
            dailyData.warning = warnings[0];
        }

        return dailyData;
    });
}

function initStandortWorkDays(
    daily: JaxAngestellteWorkDay,
    editAllPermitted: boolean,
    editAnyPermitted: boolean,
    authStore: AuthStore,
): AngestellteStandortWorkDay[] {
    return daily.standortWorkDays.map(day => {
        const editPermitted = editAllPermitted
            || (editAnyPermitted
                && isPresent(day.kinderOrtId)
                && authStore.hasPermission(
                    PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT
                    + day.kinderOrtId));

        return Object.assign({editPermitted}, day);
    });
}

function initAbsences(
    daily: JaxAngestellteWorkDay,
    editAllPermitted: boolean,
    editAnyPermitted: boolean,
    authStore: AuthStore,
): WorkTimeControllingAbsences[] {
    return daily.absences.map(a => {
        const editPermitted = editAllPermitted
            || (editAnyPermitted
                && isPresent(a.kinderOrtId)
                && authStore.hasPermission(
                    PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT + a.kinderOrtId));

        return Object.assign({editPermitted}, a);
    });
}
