<tr ngForm #detailForm="ngForm" class="row-day-detail">
    <td colspan="2">
        <textarea type="text"
                  [rows]="5"
                  [ngModel]="''"
                  [dvlibRequirePermission]="'work_time_controlling:work_time_controlling_table_edit:general'"
                  class="form-control"
                  name="notes"
                  dvlibElasticTextInput
                  [placeholder]="'NOTIZ.PLURAL' | transloco"
                  [attr.aria-label]="'NOTIZ.PLURAL' | transloco"
                  [tooltip]="'NOTIZ.PLURAL' | transloco">
        </textarea>
    </td>

    <!-- Geplante Arbeitszeit -->
    <td>
        @for (plannedTime of state().plannedTimes(); track plannedTime) {
            <div class="work-time-controlling-card small-abstand-bottom"
                 [ngClass]="plannedTime.type === 'TERMIN' ? 'termin' : 'worktime'">
                <div class="time small-abstand-bottom">
                    <div>{{ plannedTime.timeRange.von | dvTimeFormat }}</div>
                    <div>{{ plannedTime.timeRange.bis | dvTimeFormat }}</div>
                </div>
                <div class="standort-readonly">
                    <span class="standort-content">
                        {{ plannedTime | displayName }}
                    </span>
                </div>
            </div>
        }
    </td>

    <!-- Ist Arbeitszeit -->
    <td>
        @for (workDay of state().workDays(); track workDay; let workDayIndex = $index) {
            @for (actualTimeRange of workDay.actualTimeRanges; track actualTimeRange; let index = $index) {
                <div class="work-time-controlling-card small-abstand-bottom">
                    @if (workDay.editPermitted) {
                        <button dvlibButton="link-button"
                                class="close-button"
                                [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST' | transloco"
                                [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST' | transloco"
                                (click)="removeTimeRange(workDay, index)">
                            <i class="custom-icon custom-icon-close-thin"></i>
                        </button>
                    }
                    <div class="time-inputs small-abstand-bottom">
                        <input type="time"
                               [name]="'von_' + workDayIndex + '_' + index"
                               [disabled]="!workDay.editPermitted"
                               [(ngModel)]="actualTimeRange.von"
                               validVon
                               [dateStart]="selectedDate()"
                               [dateEnd]="selectedDate()"
                               [timeEnd]="actualTimeRange.bis"
                               required
                               class="form-control input-sm no-margin"/>
                        <input type="time"
                               [name]="'bis_' + workDayIndex + '_' + index"
                               [disabled]="!workDay.editPermitted"
                               [(ngModel)]="actualTimeRange.bis"
                               validBis
                               [timeStart]="actualTimeRange.von"
                               [dateStart]="selectedDate()"
                               [dateEnd]="selectedDate()"
                               required
                               class="form-control input-sm no-margin"/>
                    </div>
                    <button dvlibButton="link-button"
                            class="standort-selection"
                            [disabled]="!workDay.editPermitted">
                        <span class="button-content">
                            @if (workDay.kinderOrtId) {
                                <span>{{ kinderOrteById()[workDay.kinderOrtId].displayName }}</span>
                            }
                            @if (workDay.kinderOrtId && workDay.kinderOrtFraktionId) {
                                <span>, </span>
                            }
                            @if (workDay.kinderOrtFraktionId) {
                                <span>{{ kinderOrtFraktionenById()[workDay.kinderOrtFraktionId].displayName }}</span>
                            }

                            @if (!workDay.kinderOrtId && !workDay.kinderOrtFraktionId) {
                                + <span transloco="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION"></span>
                            }
                        </span>
                    </button>
                </div>
            }
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST' | transloco"
                        (click)="addWorkDay()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>

    <!-- Absenzen -->
    <td>
        @for (currentAbsences of state().absences(); track currentAbsences; let absencesIndex = $index) {
            @for (absence of currentAbsences.absences; track absence; let absenceIndex = $index) {
                <div class="work-time-controlling-card small-abstand-bottom">
                    @if (currentAbsences.editPermitted) {
                        <button dvlibButton="link-button"
                                class="close-button"
                                [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE' | transloco"
                                [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE' | transloco"
                                (click)="removeAbsence(currentAbsences, absenceIndex)">
                            <i class="custom-icon custom-icon-close-thin"></i>
                        </button>
                    }
                    <div class="time-inputs small-abstand-bottom">
                        <input type="time"
                               [name]="'von_' + absenceIndex"
                               [disabled]="!currentAbsences.editPermitted"
                               [(ngModel)]="absence.von"
                               (ngModelChange)="updateAbsenceMinutes(absence)"
                               validVon
                               [dateStart]="selectedDate()"
                               [dateEnd]="selectedDate()"
                               [timeEnd]="absence.bis"
                               [required]="!!absence.bis"
                               class="form-control input-sm no-margin"/>
                        <input type="time"
                               [name]="'bis_' + absenceIndex"
                               [disabled]="!currentAbsences.editPermitted"
                               [(ngModel)]="absence.bis"
                               (ngModelChange)="updateAbsenceMinutes(absence)"
                               validBis
                               [timeStart]="absence.von"
                               [dateStart]="selectedDate()"
                               [dateEnd]="selectedDate()"
                               [required]="!!absence.von"
                               class="form-control input-sm no-margin"/>
                    </div>
                    <div class="time-inputs small-abstand-bottom">
                        <p class="input-spacer">{{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.DURATION' | transloco }}</p>
                        <input type="text"
                               dvMinutesInput
                               [name]="'duration_' + absenceIndex"
                               [disabled]="!currentAbsences.editPermitted"
                               [(ngModel)]="absence.minutes"
                               [required]="!absence.von && !absence.bis"
                               class="form-control input-sm no-margin"/>
                    </div>
                    <div class="row small-abstand-bottom">
                        <div class="col-md-12">
                            <select [name]="'absenceType_' + absenceIndex"
                                    [disabled]="!currentAbsences.editPermitted"
                                    class="form-control no-margin input-sm"
                                    [(ngModel)]="absence.absenceTypeId"
                                    required
                                    [tooltip]="'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco">
                                <option [ngValue]="''" selected>
                                    {{ 'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco }}
                                </option>
                                @for (type of absenceTypes(); track type.id) {
                                    <option [ngValue]="type.id">{{ type.name }}</option>
                                }
                            </select>
                        </div>
                    </div>
                    <button dvlibButton="link-button"
                            class="standort-selection"
                            [disabled]="!currentAbsences.editPermitted">
                        <span class="button-content">
                            @if (currentAbsences.kinderOrtId) {
                                <span>{{ kinderOrteById()[currentAbsences.kinderOrtId].displayName }}</span>
                            } @else {
                                + <span transloco="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION"></span>
                            }
                        </span>
                    </button>
                </div>
            }
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE' | transloco"
                        (click)="addAbsence()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>
    <td></td>
    <td></td>
</tr>
<tr class="row-day-detail" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
    <td>
        <dvlib-button-list>
            <button dvlibButton="primary" (click)="save($event, detailForm)">
                {{ 'COMMON.SPEICHERN' | transloco }}
            </button>
            <button dvlibButton="link-button" (click)="resetChanges()">
                {{ 'COMMON.RESET' | transloco }}
            </button>
        </dvlib-button-list>
    </td>
</tr>
