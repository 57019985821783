import {NgClass, NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
    ButtonComponent,
    DvLocalDateFormatPipe,
    DvMinutesFormatPipe,
    ElasticTextInputDirective,
    IconComponent,
} from '@dv/shared/angular';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {Displayable} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {EntityMap} from '@ngrx/signals/entities';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {
    WorkTimeControllingDayDetailComponent,
} from '../work-time-controlling-day-detail/work-time-controlling-day-detail.component';
import {SortBy, SortOrder, warningClasses, WorkTimeControllingTableData} from './work-time-controlling-table.models';
import {
    WorkTimeControlServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-control.service';

type TableHeader = {
    translationKey: string;
    sortBy?: SortBy;
};

@Component({
    selector: 'dv-work-time-controlling-table',
    standalone: true,
    imports: [
        NgClass,
        TooltipModule,
        ElasticTextInputDirective,
        TranslocoModule,
        NgTemplateOutlet,
        FormsModule,
        IconComponent,
        ButtonComponent,
        UIRouterModule,
        DvLocalDateFormatPipe,
        DvMinutesFormatPipe,
        WorkTimeControllingDayDetailComponent,
    ],
    templateUrl: './work-time-controlling-table.component.html',
    styleUrl: './work-time-controlling-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingTableComponent {

    public data = input.required<WorkTimeControllingTableData[]>();
    public sortBy = input.required<SortBy>();
    public sortOrder = input.required<SortOrder>();
    public kinderOrteEntities = input.required<EntityMap<Displayable>>();
    public kinderOrtFraktionenEntities = input.required<EntityMap<Displayable>>();
    public absenceTypes = input<JaxWorkTimeControllingAbsenceType[]>([]);

    public readonly toggleSort = output<SortBy>();
    public readonly toggleAngestellte = output<string>();
    public readonly toggleDay = output<{ angestellteId: string; date: BackendLocalDate }>();
    public readonly warningClasses = warningClasses;
    public readonly confirmEdit = output<WorkTimeControlServiceSaveManualEditRequestParams>();

    public tableHeaders: TableHeader[] = [
        {
            translationKey: 'EMPLOYEE',
            sortBy: 'displayname',
        },
        {
            translationKey: 'TARGET_HOURS',
            sortBy: 'expectedMinutes',
        },
        {
            translationKey: 'PLANNED_HOURS',
            sortBy: 'plannedMinutes',
        },
        {
            translationKey: 'IST',
            sortBy: 'actualMinutes',
        },
        {
            translationKey: 'ABSENCE',
            sortBy: 'absenceMinutes',
        },
        {
            translationKey: 'TIME_BALANCE',
            sortBy: 'timeBalance',
        },
        {
            translationKey: 'FERIENKONTINGIENT',
        },
    ];
}
